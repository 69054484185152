import axios from 'axios';
import { CONFIG } from '../config'
import { encrypt, decrypt } from './crypto'

const instance = axios.create({
    baseURL: CONFIG.server_domain,
    timeout: 60000,
    headers: { 'Content-Type': 'application/json' },
});

export async function request (url, data) {
    data.db = CONFIG.app_id;
    let encryptedData = encrypt(data)
    try {
        const res = await instance.request({
            url,
            method: 'post',
            data: {
                data: encryptedData
            },
        });
        res.data = JSON.parse(decrypt(res.data))
        return res
    } catch (err) {
        console.error(err);
        return {
            status: 'error',
        }
    }
}

export async function requestPay (url, data) {
    data.db = CONFIG.app_id;
    try {
        const res = await instance.request({
            url,
            method: 'post',
            data
        });
        res.data = JSON.parse(decrypt(res.data))
        return res
    } catch (err) {
        console.error(err);
        return {
            status: 'error',
        }
    }
}