import { CONFIG, WORK_PREFIX } from '../config'

export function throttle(func, delay) {
    let lastCalled = 0;
    return function (...args) {
        const now = new Date().getTime();
        if (now - lastCalled >= delay) {
            func(...args);
            lastCalled = now;
        }
    };
}


// export function getWebConfig(key) {
//     const hostname = window.location.hostname;
//     let webConfig = CONFIG.list.find(item => item.domain === hostname)
//     if(!webConfig) {
//         webConfig = {
//             domain: 'localhost', // 域名，标识
//             app_name: 'localhost', // Header 左上角显示
//             app_id: '00',
//             lang: 'both', // 支持语言
//             vip_price: {
//                 starter: 2.8,
//                 pro: 9.8,
//                 max: 19.8,
//             }
//         }
//     }
//     return webConfig[key]
// }

export function getInitialLang() {
    let cacheLang = localStorage.getItem('lang')
    if(cacheLang) {
        return cacheLang
    } else {
        return 'zh'
        // let langSupported = getWebConfig('lang')
        // return langSupported === 'both' ? 'zh' : langSupported
    }
}


export function formatAmount(amount) {
    return Number(amount).toFixed(2)
}

export function getRandomInt(min, max) {
    // 生成随机数
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getUrlParams(key) {
    const params = new URLSearchParams(window.location.search);
    return params.get(key)
}

export const getFirstLetter = (str) => {
    if (typeof str !== 'string' || str.length === 0) {
        return str;
    }
    return str.charAt(0).toUpperCase()
}

// str: 20241215-1-6
export function generateWorkImgUrl (str) {
    const [date, folder, total] = str.split('-')
    return {
        date: date,
        folder: folder,
        total: total
    }
}

export function generateUrl(date, folder, total) {
    const list = []
    for(let i = 1; i <= total; i++) {
        list.push(`${WORK_PREFIX}${date}/${folder}/p${i}.png`)
    }
}

export function formatDate(dateString) {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${year}.${month}.${day}`;
}

export function calculateUnitPrice(amount, quantity, decimals = 2) {
    let unitPrice = amount / quantity;
    return parseFloat(unitPrice.toFixed(decimals));
}

export const getTotalPhotos = (list) => {
    let count = 0
    list.forEach(item => {
        count += Number(item.total)
    })
    return count;
}