export const CONFIG = {
    default_parent: '32276420',
    server_domain: "https://www.c8c.icu",
    // server_domain: "http://127.0.0.1:3000",
    app_id: 'dalle', // 数据库名
    exchange_rate: 7.26, // 汇率
    domain: 'riai.cc',
    email: 'openai.online.cc@gmail.com', // 联系邮箱
    list: [
        {
            domain: 'localhost', // 域名，标识
            app_name: 'localhost', // Header 左上角显示
            app_id: '00',
            lang: 'both', // 支持语言
            vip_price: {
                starter: 2.8,
                pro: 9.8,
                max: 19.8,
            }
        },
        {
            domain: 'openai-online.cc', // 域名，标识
            app_name: 'OpenAI Online', // Header 左上角显示
            app_id: '01',
            lang: 'both', // 支持语言 zh, en, both
            vip_price: {
                starter: 2.8,
                pro: 9.8,
                max: 19.8,
            }
        },
        {
            domain: 'xn--sqrz0ep07c.com', // 域名，标识
            app_name: '绘图君.com', // Header 左上角显示
            app_id: '02',
            lang: 'both', // 支持语言
            vip_price: {
                starter: 2.8,
                pro: 9.8,
                max: 19.8,
            }
        },
        {
            domain: 'xn--sqrz0ep07c.xyz', // 域名，标识
            app_name: '绘图君.xyz', // Header 左上角显示
            app_id: '03',
            lang: 'both', // 支持语言
            vip_price: {
                starter: 2.8,
                pro: 9.8,
                max: 19.8,
            }
        }
    ]
}

export const WORK_PREFIX = 'https://ths-h5-1300040098.cos.ap-guangzhou.myqcloud.com/riai/'