import { CACHE_KEY, UserType, ADMIN_NAMW } from '../consts';
import { request } from './request'


export function setUserToStorage(userInfo) {
  localStorage.setItem(CACHE_KEY.user_info, JSON.stringify(userInfo));
}
export function getUserFromStorage() {
  let userInfo = localStorage.getItem(CACHE_KEY.user_info)
  return userInfo ? JSON.parse(userInfo) : {}
}

export async function refreshUserInfo () {
  const userInfo = getUserFromStorage()
  if(userInfo.user_id) {
    const info = await getUserInfoFromDB({user_id: userInfo.user_id});
    setUserToStorage(info)
  }
}

export function isUserValidVip(userInfo) {
  if (!userInfo) return false;
  const currentDate = new Date();
  const currentDateTimestamp = currentDate.getTime();
  return userInfo.vip_type !== UserType.Default && currentDateTimestamp <= userInfo.end_date;
}

export async function getUserInfoFromDB(params) {
  try {
    let reqParams = {
      ...params,
      pageNum: 1,
      pageSize: 10,
    }
    const res = await request(
      '/api/dalle',
      {
        operation: 'queryUsers',
        data: reqParams,
      }
    )
    if (res.data.data) {
      return res.data.data[0] || {}
    }
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function countUser(params) {
  try {
    const res = await request(
      '/api/dalle',
      {
        operation: 'countUser',
        data: {
          ...params,
        }
      }
    )
    return res.data.data
  } catch (error) {
    console.error(error);
    return 0;
  }
}

export async function addUserToDB(userInfo) {
  try {
    const res = await request(
      '/api/dalle',
      {
        operation: 'addUser',
        data: {
          ...userInfo,
        }
      }
    )
    return res.data.success
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function checkUserExist(params) {
  try {
    const res = await request(
      '/api/dalle',
      {
        operation: 'countUser',
        data: {
          ...params,
        }
      }
    )
    return res.data.data > 0
  } catch (error) {
    console.error(error);
    return [];
  }
}

export function generateUniqueUserId() {
  const user_id = Math.floor(Math.random() * 90000000) + 10000000;
  return user_id;
}

export async function generateUniqueUserIdWithCheck() {
  let user_id = generateUniqueUserId();
  let isExsit = await checkUserExist({ user_id: user_id })
  while (isExsit) {
    user_id = generateUniqueUserId();
  }
  return String(user_id);
}

export async function joinAgent(user_id, agent_level) {
  try {
    const userInfo = await getUserInfoFromDB({ user_id: user_id });
    const newUserInfo = {
      ...userInfo,
      agent_level: agent_level,
    }
    await request(
      '/api/dalle',
      {
        operation: 'updateUser',
        data: {
          _id: userInfo._id,
          ...newUserInfo,
        },
      }
    )
    setUserToStorage(newUserInfo)
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function modifyUserPassword(newUserInfo) {
  try {
    await request(
      '/api/dalle',
      {
        operation: 'updateUser',
        data: {
          _id: newUserInfo._id,
          ...newUserInfo,
        },
      }
    )
    setUserToStorage(newUserInfo)
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const isAdmin = () => {
  const userInfo = getUserFromStorage()
  return userInfo.is_admin
};


export const isRoot = () => {
  const userInfo = getUserFromStorage()
  return userInfo.is_root
};

export const getHasUnlock = (album_id) => {
  const userInfo = getUserFromStorage()
  if(userInfo.username === ADMIN_NAMW) {
    return true
  }
  if ((userInfo.unlock || []).includes(ADMIN_NAMW)) {
    return true
  }
  if ((userInfo.unlock || []).includes(album_id)) {
    return true
  }
  return false
}